
.whlink{
    width: 100%;
    text-decoration: none;
    padding: 15px;
    margin: 5px;
    border: 1px solid #97afd5ab;
    border-radius: 10px;
    color: white;
}

.bin{
    margin-top: 1em;
    margin-bottom: 1em;
}

.histhd{
    margin-top: 2em;
    margin-bottom: 1em;
    color: white;
}