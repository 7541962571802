.profilebod{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4em;
}

.userinfo{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#avatr{
    width: 6em;
    height: 6em;
}

.userinfo>h3{
    color: white;
    margin-top: 1em;
}

.afpr{
    width: fit-content;
    padding: 10px;
    color: white;
    background-color: #f42f54;
    border-radius: 999px;
    margin-top: 1em;
    font-size: 14px;
}

.bfpr{
    background-color: transparent;
    border: 1px solid #f42f54;
    color: #f42f54;
    border-radius: 999px;
    margin-top: 1em;
    font-size: 14px;
    padding: 10px;
}

.infoprofile{
    width: 80%;
}

.infoprofile>p{
    padding: 15px;
    color: white;
    font-size: 15px;
    border: 1px solid #f42f54;
    border-radius: 10px;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.plink{
    width: 90%;
    display: flex;
    align-items: center;
    padding: 15px;
    text-decoration: none;
    height: 80px;
    border: 1px solid #f42f54;
    border-radius: 10px;
    margin-top: 1em;
    margin-bottom: 1em;
    color: aliceblue;
    font-size: 14px;
}

.plink>div{
    height: 4em;
    width: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    border-radius: 10px;
    justify-content: center;
    background-color: #f42f537e;
    margin-right: 1em;
}

.profilebod>h3{
    font-size: 25px;
    color: white;
}

.imageids{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.imageids>label>.imagerecpt{
    margin: 10px;
}





@media only screen and (max-width: 1112px){
    .profilebod{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 4em;
    }
    .infoprofile{
        width: 95%;
    }

    .plink{
        width: 95%;
        display: flex;
        align-items: center;
        padding: 15px;
        text-decoration: none;
        height: 80px;
        border: 1px solid #f42f54;
        border-radius: 10px;
        margin-top: 1em;
        margin-bottom: 1em;
        color: aliceblue;
        font-size: 14px;
    }

    
    .imageids{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
    }

    .profilebod{
        padding-bottom: 4rem;
    }
}