.marketbod{
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    background-color: transparent;
    align-items: center;
}

.marketbod::-webkit-scrollbar{
    display: none;
}

.marketfstbox{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
}

.chartbox1{
    width: 80%;
}

.chartpay{
    width: 20%;
}

.inputui{
    margin-top: .5em;
    margin-bottom: .5em;
    width: 85%;
}


.chartpay{
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    color: white;
}

.trdbtn{
    width: 85%;
}

.trdbtn>button{
    padding: 10px;
    width: 50%;
    border: none;
    cursor: pointer;
}

.buybtn{
    background-color: green;
    color: white;
}

.chartpaybal{
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-weight: 700;
}

.fndacc{
    text-decoration: none;
    color: white;
    width: 85%;
    padding: 15px;
    background-color: rgba(0, 0, 255, 0.534);
    text-align: center;
    font-weight: 700;
    margin-top: 3em;
    border-radius: 10px;
}

.screener{
    width: 100%;
}

#outlined-basic{
    width: 100%;
    border: 1px solid #97afd5;
    color: white;
    border-radius: 10px;
    padding: 15px;
    background-color: transparent;
    zoom: 1;
    outline-width: 0;
}










@media only screen and (max-width: 1112px){
    .marketbod{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        background-color: transparent;
        align-items: center;
        padding-top: 1rem;
    }

    .marketfstbox{
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    
    .chartbox1{
        width: 95%;
    }
    
    .chartpay{
        width: 95%;
    }

    .fndacc{
        text-decoration: none;
        color: white;
        width: 85%;
        padding: 15px;
        background-color: green;
        text-align: center;
        font-weight: 700;
        margin-bottom: 1em;
        margin-top: 1em;
        border-radius: 10px;
    }
}