.bottom{
    position: fixed;
    bottom: 0;
    background-color: #0f1932;
    height: 60px;
    width: 100%;
    display: none;
    justify-content: center;
    border-top: 1px solid #c2c2c2;
    z-index: 4000;
}

.darkbottom{
    position: fixed;
    bottom: 0;
    background-color: black;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid #3a3a3a;
}

.bottombod{
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: center;
    position: relative;

}

.bottomlinks{
    width: 25%;
    display: flex;
    justify-content: center;
    color: black;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    position: relative;
    z-index: 4000;
}

.bottomlinks:active{
    transform: scale(0.96);
}

.darkbottomlinks{
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    position: relative;
    z-index: 4000;
}

.darkbottomlinksL{
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: grey;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    position: relative;
    opacity: .6;
    transition: 1s;
    z-index: 4000;
}

.bottomlinksL{
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: grey;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    position: relative;
    opacity: .6;
    transition: 1s;
    z-index: 4000;
}

.darkbottomlinks:active{
    transform: scale(0.96);
}

.notnum{
    display: flex;
    width: fit-content;
    height: 1.5em;
    position: relative;
}

.notnumdark{
    display: flex;
    width: fit-content;
    height: 1.5em;
    position: relative;
}

.notnum>div{
    position: absolute;
    top: -.25em;
    right: -.3em;
    background-color: red;
    color: white;
    width: 1.2em;
    height: 1.2em;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid white;
}

.notnumdark>div{
    position: absolute;
    top: -.25em;
    right: -.3em;
    background-color: red;
    color: white;
    width: 1.2em;
    height: 1.2em;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid black;
}

.notnum>div>p{
    font-size: 10px;
}

.notnumdark>div>p{
    font-size: 10px;
}

#bttmmicons{
    width: 24px;
    height: 24px;
}

.tube{
    width: 25%;
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    transition: left 400ms ease;
}

 .tubelight {
    /* transform: translateX(-20%); */
    width: 50%;
    height: 5px;
    border-radius: 5px;
    background: #f42f54;
    z-index: 10;
    display: flex;
    justify-content: center;
    margin-bottom: 3.7em;
  }
  
  .light-ray {
    width: 200%;
    height: 130px;
    clip-path: polygon(5% 50%, 25% 0px, 75% 0px, 95% 50%);
    background: linear-gradient(to bottom, #f42f53ab -50%, rgba(255, 255, 255, 0) 90%   );
    pointer-events: none;
  }



@media only screen and (max-width: 800px){
    .bottombod{
        width: 100%;
        display: flex;
        align-items: center;
    }

    .tube{
        width: 25%;
        position: absolute;
        left: 6.3em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .bottom{
        position: fixed;
        bottom: 0;
        background-color: #0f1932;
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        border-top: 1px solid #c2c2c2;
    }
    
    .darkbottom{
        position: fixed;
        bottom: 0;
        background-color: black;
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        border-top: 1px solid #3a3a3a;
    }
}